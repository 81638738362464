import { Flex, Heading, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { OrgDto } from "../../../../common/redux/reducers/authStateReducer";
import { useConfirmationModal } from "../../../../common/useConfirmationModal";
import { displayPersonName } from "../../../contracts/view-single/sharing/AddExternalParticipantModal";
import { UserRow } from "./UserRow";
import { User } from "./types";

interface Props {
  org: OrgDto;
}

export const Users = ({ org }: Props) => {
  const { t } = useTranslation();

  const confirmationModal = useConfirmationModal();

  const users: User[] = org.users.map((e) => ({
    id: e.person.id,
    name: displayPersonName(e.person),
    email: e.person.email,
    roles: e.roles,
  }));

  return (
    <>
      {confirmationModal.modal}
      <Flex mt="20px" flexDirection={"column"} maxWidth={"full"} width={"full"} position={"relative"}>
        <Heading as="h2" size="md">
          {t("Users")}
        </Heading>
        <Flex border="1px solid" borderColor="smBorder" borderRadius={10} maxWidth="full" overflowX="auto" mt="20px">
          <Table variant="simple" mb="30px">
            <Thead>
              <Tr>
                <Th>{t("Name")}</Th>
                <Th>{t("Email")}</Th>
                <Th minW={"250px"}>{t("Role")}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((u) => {
                return <UserRow key={u.id} user={u} confirmationModal={confirmationModal} />;
              })}
              {users.length === 0 && (
                <Tr>
                  <Td colSpan={3} width={"100%"} textAlign="center">
                    {t("No users have been added")}.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
    </>
  );
};
