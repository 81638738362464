import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { OrgPublicProfileDto } from "../../../../autogen/bff-api";
import { TipTap } from "../../../../common/input/TipTap/TipTap";
import { requireBooleanEnvVar } from "../../../../config";

interface Props {
  organization: OrgPublicProfileDto;
}

export const AboutUs = ({ organization }: Props) => {
  const { t } = useTranslation();
  return (
    <Flex
      border="1px solid"
      borderColor="smBorder"
      flexDirection="column"
      alignItems="start"
      justifyContent="start"
      borderRadius="lg"
      padding="25px"
      bg="smBackground"
      width="full"
      height="auto"
    >
      <Box>
        <Text fontSize={"md"} fontWeight="bold">
          {t("About us")}
        </Text>
      </Box>
      <Box>
        {requireBooleanEnvVar("VITE_ENABLE_DUMMY_COMPANY_PAGE") ? (
          <Text fontSize={"sm"} color="smMuted" whiteSpace={"pre-line"}>
            {
              "Coupa Software (NASDAQ:COUP) is the cloud platform for business spend management (BSM). Coupa empowers companies around the world with the visibility and control they need to spend smarter and safer. To learn more about how Coupa can help you spend smarter, visit www.coupa.com. Read Coupa Blog https://www.coupa.com/blog/ or follow @Coupa on Twitter."
            }
          </Text>
        ) : organization.aboutUs ? (
          <TipTap content={organization.aboutUs} hasBorder={false} editable={false} padding="0" />
        ) : (
          <Text as="span" fontSize={"sm"} color="smMuted">
            <i>{t("Not provided")}.</i>
          </Text>
        )}
      </Box>
    </Flex>
  );
};
