import { Flex, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { OrgDto } from "../../../../common/redux/reducers/authStateReducer";
import { InviteNewUser } from "./InviteNewUser";
import { InvitedUsersTable } from "./InvitedUsersTable";
export interface Props {
  org: OrgDto;
}

export const Invites = ({ org }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex mt="20px" flexDirection={"column"}>
        <Flex flexDirection={"column"} borderRadius="10px" backgroundColor="smBackgroundSecondary" p="5">
          <Heading as="h2" size="md">
            {t("Invite new user")}
          </Heading>
          <InviteNewUser />
        </Flex>
      </Flex>
      <Flex mt="20px" mb="50px" flexDirection={"column"}>
        <Flex flexDirection={"column"} borderRadius="lg" pt="5" pb="8">
          <Heading as="h2" size="md">
            {t("Invites sent")}
          </Heading>
          <InvitedUsersTable org={org} />
        </Flex>
      </Flex>
    </>
  );
};
