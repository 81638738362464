import { Flex, Grid, GridItem, Heading, Icon, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCode, FaMicrosoft, FaPlus, FaTools, FaUsers } from "react-icons/fa";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { Layout } from "../../../../common/layout/Layout";
import { UserManagementTab, useQueryParams } from "../../../../common/useQueryParams";
import { CONTENT_WIDTH } from "../../../../config";
import { Advanced } from "./Advanced";
import { ApiAccess } from "./ApiAccess";
import { AzureConfiguration } from "./AzureConfiguration";
import { Invites } from "./Invites";
import { Users } from "./Users";
export interface AddAdminFormValues {
  email: string;
}

export const AccessManagementPage = () => {
  const authState = useLoggedInWithOrgContextState();
  const params = useQueryParams();
  const tab = params.get("tab");
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (tab === "users") setTabIndex(0);
    if (tab === "invites") setTabIndex(1);
    if (tab === "azure") setTabIndex(2);
    if (tab === "advanced") setTabIndex(3);
    if (tab === "api") setTabIndex(4);
  }, [tab]);

  return (
    <Layout>
      <Grid
        maxWidth="full"
        height="full"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`
            "leftContent content rightContent"
            `}
      >
        <GridItem maxWidth="full" area={"content"} px={{ sm: "2", base: "2.5" }}>
          <Flex flexDirection="column" width="full" maxWidth="full" height="full">
            <Flex alignItems="center" borderBottom={"1px solid"} borderColor="smBorder" pt="4" pb="5">
              <Heading as="h2" size="lg">
                {t("Access management")}
              </Heading>
            </Flex>
            <GridItem maxWidth="full">
              <Tabs
                maxWidth="full"
                mt="5"
                index={tabIndex}
                onChange={(index) => {
                  setTabIndex(index);
                  params.setSelectedUserManagementTab(indexToQueryValue(index));
                }}
                variant="line"
                colorScheme="teal"
              >
                <TabList>
                  <Tab>
                    <Icon mr="2" as={FaUsers} /> {t("Users")}
                  </Tab>
                  <Tab>
                    <Icon mr="2" as={FaPlus} boxSize={"3"} />
                    {t("Add User")}
                  </Tab>
                  <Tab>
                    <Icon mr="2" as={FaMicrosoft} />
                    {t("Entra ID")}
                  </Tab>
                  <Tab>
                    <Icon mr="2" as={FaTools} />
                    {t("Advanced")}
                  </Tab>
                  <Tab>
                    <Icon mr="2" as={FaCode} />
                    {t("API")}
                  </Tab>
                </TabList>
                <TabPanels maxWidth="full">
                  <TabPanel maxWidth="full">
                    <Users org={authState.selectedOrg} />
                  </TabPanel>
                  <TabPanel>
                    <Invites org={authState.selectedOrg} />
                  </TabPanel>
                  <TabPanel>
                    <AzureConfiguration />
                  </TabPanel>
                  <TabPanel>
                    <Advanced />
                  </TabPanel>
                  <TabPanel>
                    <ApiAccess />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>
          </Flex>
        </GridItem>
      </Grid>
    </Layout>
  );
};

const indexToQueryValue = (index: number): UserManagementTab => {
  if (index === 0) return "users";
  if (index === 1) return "invites";
  if (index === 2) return "azure";
  if (index === 3) return "advanced";
  if (index === 4) return "api";
  throw Error("Unknown tab");
};
