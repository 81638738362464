import { Box, Flex, Icon, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaEnvelope, FaIdCard, FaLink, FaMapMarkerAlt, FaUsers } from "react-icons/fa";
import { OrgPublicProfileDto } from "../../../../autogen/bff-api";
import { displayOrganizationSize } from "../../../../common/formatting/displayOrganizationSize";
import { useBaseData } from "../../../../common/useBaseData";

interface Props {
  organization: OrgPublicProfileDto;
}

export const Details = ({ organization }: Props) => {
  const baseData = useBaseData();
  const { t } = useTranslation();

  if (!baseData) return <div>{t("Loading")}...</div>;

  return (
    <Flex
      flexDirection={"column"}
      alignItems="start"
      justifyContent={"start"}
      borderRadius={"10px"}
      boxShadow="0 3px 15px rgba(71, 85, 105, 0.16)"
      padding="25px"
      backgroundColor="smBackground"
      width="full"
    >
      <Box>
        <Text fontSize={"md"} fontWeight="bold">
          {t("Details")}
        </Text>
        {organization.organizationNumber && (
          <Flex mt="5px">
            <Icon as={FaIdCard} w="15px" h="15px" mt="4px" color="smMuted" />
            <Text fontSize={"sm"} color="smMuted" ml="10px">
              {organization.organizationNumber}
            </Text>
          </Flex>
        )}
        <Flex mt="5px">
          <Icon as={FaMapMarkerAlt} w="15px" h="15px" mt="4px" color="smMuted" />
          <Text fontSize={"sm"} color="smMuted" ml="10px">
            {organization.country.name}
          </Text>
        </Flex>
        {organization.size && (
          <Flex mt="5px">
            <Icon as={FaUsers} w="15px" h="15px" mt="4px" color="smMuted" />
            <Text fontSize={"sm"} color="smMuted" ml="10px">
              {displayOrganizationSize(organization.size)}
            </Text>
          </Flex>
        )}
        {organization.homePageUrl && (
          <Flex mt="5px">
            <Icon as={FaLink} w="13px" h="15px" mt="6px" ml="1px" color="smMuted" />
            <Text ml="11px" fontSize={"sm"}>
              <Link
                color="smPrimary"
                href={
                  organization.homePageUrl.indexOf("https://") === -1
                    ? `https://${organization.homePageUrl}`
                    : "organization.homePageUrl"
                }
                target={"_blank"}
              >
                {organization.homePageUrl}
              </Link>
            </Text>
          </Flex>
        )}
        <Flex mt="5px">
          <Icon as={FaEnvelope} w="15px" h="15px" mt="4px" color="smMuted" />
          <Text fontSize={"sm"} color="smMuted" ml="10px">
            {organization.owner.email} ({`${organization.owner.firstName} ${organization.owner.lastName}`})
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};
