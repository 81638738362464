import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  OrganizationEntryDto,
  useGetOrganizationEntryQuery,
  useUpdateOrganizationEntryMutation,
} from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { isCreator } from "../../../../common/auth/utils";
import { useApiError } from "../../../../common/errors/useApiError";
import { ModalSkeleton } from "../../../../common/ModalSkeleton";
import { OrganizationTypeTag } from "../../../../common/organization/OrganizationTypeTag";

interface Props {
  entryId: string;
  onClose: () => void;
  linkWithExistingOrg?: () => void;
}

export const OrganizationEntryModal = ({ entryId, onClose, linkWithExistingOrg }: Props) => {
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const errorDisplayer = useApiError();

  const canUpdateOrgEntries = isCreator(authState.selectedOrg.roles);

  const { data: entry, isLoading } = useGetOrganizationEntryQuery({ entryId });

  const [updateOrgEntry, { isLoading: isUpdating }] = useUpdateOrganizationEntryMutation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateOrgEntry = useCallback(
    debounce(async (entry: OrganizationEntryDto) => {
      await updateOrgEntry({
        entryId: entry.id,
        updateOrganizationEntryRequest: {
          ...entry,
          countryId: entry.country.id,
        },
      })
        .unwrap()
        .catch((e) => {
          console.log(e);
          errorDisplayer.trigger(e);
        });
    }, 300),
    []
  );

  return (
    <Modal isOpen={true} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent pb="10px">
        <ModalHeader>
          {entry && (
            <>
              {canUpdateOrgEntries ? (
                <Flex align="center">
                  <Input
                    defaultValue={entry.name}
                    onChange={(e) => {
                      const name = e.target.value;
                      if (name) debouncedUpdateOrgEntry({ ...entry, name });
                    }}
                  />
                  {isUpdating && <Spinner color="smMuted" ml="-8" />}
                </Flex>
              ) : (
                entry.name
              )}
            </>
          )}
          {!entry && isLoading && `${t("Loading")}...`}
          <Box mt="5px">
            <OrganizationTypeTag type="OrganizationEntry" />
          </Box>
        </ModalHeader>
        <ModalBody mt="-15px">
          {entry && (
            <Flex backgroundColor="smBackgroundSecondary" flexDirection={"column"} padding="2.5" rounded="lg">
              <Text fontWeight="bold">{t("Country")}</Text>
              <Text>{entry.country.name}</Text>
              <br />
              <Text fontWeight="bold">{t("Organization number")}</Text>
              {canUpdateOrgEntries ? (
                <Flex align="center" pt="1">
                  <Input
                    backgroundColor="smBackground"
                    defaultValue={entry.organizationNumber}
                    onChange={(e) => {
                      const organizationNumber = e.target.value;
                      if (organizationNumber) debouncedUpdateOrgEntry({ ...entry, organizationNumber });
                    }}
                  />
                  {isUpdating && <Spinner color="smMuted" ml="-8" />}
                </Flex>
              ) : (
                <Text>{entry.name}</Text>
              )}
            </Flex>
          )}
          {!entry && isLoading && <ModalSkeleton />}
        </ModalBody>
        {!entry?.link && linkWithExistingOrg && canUpdateOrgEntries && (
          <ModalFooter mt="-10px">
            <Button variant="solid" colorScheme={"purple"} onClick={linkWithExistingOrg}>
              {t("Link with existing org")}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
