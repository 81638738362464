import { Box, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { ProductGroup, ProductGroupDraft } from "../types";

export const ProductGroupPreview = ({ group }: { group: ProductGroupDraft | ProductGroup }) => {
  return (
    <Box
      p="5"
      width="full"
      backgroundColor="smBackground"
      border="1px"
      borderColor="smBorder"
      rounded="lg"
      maxWidth="full"
      overflowX="auto"
    >
      <Table width="full" variant="simple" size="sm">
        <Thead>
          <Tr>
            {group.productFields
              .filter((f) => f.populatedBy === "Buyer")
              .map((field) => (
                <Th key={field.id} position="relative">
                  <Text>{field.name}</Text>
                </Th>
              ))}
            <Th position="relative">
              <Text>{t("Quantity")}</Text>
            </Th>
            <Th position="relative">
              <Text>{t("Unit Price")}</Text>
            </Th>
            {group.productFields
              .filter((f) => f.populatedBy === "Supplier")
              .map((field) => (
                <Th key={field.id} position="relative">
                  <Text>{field.name}</Text>
                </Th>
              ))}
          </Tr>
        </Thead>
        <Tbody>
          {group.products?.slice(0, 3).map((product) => (
            <Tr key={product.id}>
              {group.productFields
                .filter((f) => f.populatedBy === "Buyer")
                .map((field) => (
                  <Td key={field.id} position="relative" verticalAlign="top">
                    <Text maxHeight="8" overflow="hidden">
                      {product.productFields.find((f) => f.id === field.id)?.value}
                    </Text>
                    <Box
                      width="full"
                      position="absolute"
                      top="5"
                      bottom="0"
                      left="0"
                      bgGradient="linear(to bottom, transparent, smBackground)"
                    ></Box>
                  </Td>
                ))}
              <Td position="relative" verticalAlign="top">
                <Text maxHeight="8" overflow="hidden">
                  {product.quantity.value}
                </Text>
                <Box
                  width="full"
                  position="absolute"
                  top="5"
                  bottom="0"
                  left="0"
                  bgGradient="linear(to bottom, transparent, smBackground)"
                ></Box>
              </Td>
              <Td position="relative" verticalAlign="top">
                {/* Unit Price placeholder */}
              </Td>
              {group.productFields
                .filter((f) => f.populatedBy === "Supplier")
                .map((field) => (
                  <Td key={field.id} position="relative" verticalAlign="top">
                    <Text maxHeight="8" overflow="hidden">
                      {product.productFields.find((f) => f.id === field.id)?.value}
                    </Text>
                    <Box
                      width="full"
                      position="absolute"
                      top="5"
                      bottom="0"
                      left="0"
                      bgGradient="linear(to bottom, transparent, smBackground)"
                    ></Box>
                  </Td>
                ))}
            </Tr>
          ))}
        </Tbody>
        <TableCaption>{`${t("Preview")} - ${group.products?.length} ${t("products loaded")}`}</TableCaption>
      </Table>
    </Box>
  );
};
