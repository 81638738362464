import { Button, Flex, FormControl, FormLabel, Icon, Spinner, Text } from "@chakra-ui/react";
import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import ProgressBar from "@uppy/progress-bar";
import { DragDrop } from "@uppy/react";
import { t } from "i18next";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  bffApi,
  ProjectDto,
  useCreateProjectMutation,
  useCreateUploadForProjectMutation,
  useStartDocumentChatMutation,
  useUpdateProjectMutation,
} from "../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../common/errors/useApiError";
import { getPusher } from "../../common/pusher";
import { useAppDispatch } from "../../common/redux/hooks";
import { urls } from "../../urls";
import {
  displayPersonName,
  displayPersonNameWithEmail,
} from "../contracts/view-single/sharing/AddExternalParticipantModal";

enum CreateProjectWithAIState {
  Idle,
  IsInitializingProject,
  ProjectInitialized,
  UploadCreated,
  UploadScanned,
  DocumentCreated,
  IsAnalyzingDocument,
  DocumentAnalyzed,
  ProjectCreated,
}

export const isFile = (file: File | Blob): file is File => {
  return (file as File).lastModified !== undefined;
};

const createPrompt = (loggedInOrg: string) => `
  Based on the following text, populate the values in the following json:

  {
    "name": string, // Should be short but descriptive.
    "description": string, // Should contain a short description.
    "startDate": null, // Should be formatted as yyyy-MM-dd. Should be null if there is no start date.
    "endDate": null, // Should be formatted as yyyy-MM-dd. Should be null if there is no end date.
    "projectResponsible": {
      fullName?: string;
      email?: string;
    },
    "sourcingResponsible": {
      fullName?: string;
      email?: string;
    },
    "documentPages": number; // Number of pages in the text
  }

  The current user is logged in on behalf of ${loggedInOrg}

  It is very important that you return a plain, valid json of the provided format only.
`;

export const CreateProjectWithAI = ({ setShowModal }: { setShowModal: Dispatch<SetStateAction<boolean>> }) => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();
  const navigate = useNavigate();
  const errorDisplayer = useApiError();

  const [createProjectState, setCreateProjectState] = useState<CreateProjectWithAIState>(CreateProjectWithAIState.Idle);

  const [createProject, { isLoading: isCreatingProject }] = useCreateProjectMutation();
  const [updateProject] = useUpdateProjectMutation();
  const [createUpload] = useCreateUploadForProjectMutation();
  const [analyzeDocument, { error: analyzeDocumentError }] = useStartDocumentChatMutation();

  const [target, setTarget] = useState<string>();
  const [project, setProject] = useState<ProjectDto>();
  const [pagesAnalyzed, setPagesAnalyzed] = useState<number>();

  useEffect(() => {
    setTarget("#dnd");
  }, []);

  const documentAddedHandler = useCallback(async () => {
    if (!project?.id) return;
    setCreateProjectState(CreateProjectWithAIState.UploadScanned);
    const projectWithDocument = await dispatch(
      bffApi.endpoints.getProject.initiate({
        projectId: project.id,
      })
    ).unwrap();
    setProject(projectWithDocument);
    if (projectWithDocument?.documents?.[0]) {
      setProject(projectWithDocument);
      setCreateProjectState(CreateProjectWithAIState.DocumentCreated);
    } else {
      const e = Error("Illegal state - no documents when handling added document");
      errorDisplayer.trigger(e);
      throw e;
    }
  }, [project?.id, dispatch, errorDisplayer]);

  useEffect(() => {
    const channel = project?.id ? getPusher().subscribe(project.id) : undefined;
    channel?.bind("document-added", documentAddedHandler);

    return () => {
      channel?.unbind("document-added", documentAddedHandler);
    };
  }, [documentAddedHandler, project?.id]);

  const getPersonByEmailOrName = useCallback(
    ({ email, fullName }: { email?: string; fullName?: string }) => {
      return (
        authState.selectedOrg.users.find((u) => u.person.email === email)?.person ??
        authState.selectedOrg.users.find((u) => displayPersonName(u.person) === fullName)?.person
      );
    },
    [authState.selectedOrg.users]
  );

  useEffect(() => {
    if (createProjectState === CreateProjectWithAIState.DocumentCreated) {
      const document = project?.documents?.[0];
      if (!document) {
        const e = Error("Illegal state - no documents before analyzing documents");
        errorDisplayer.trigger(e);
        throw e;
      }
      setCreateProjectState(CreateProjectWithAIState.IsAnalyzingDocument);
      analyzeDocument({
        docId: document.id,
        chatCompletionRequest: {
          model: "gpt-4o",
          messages: [{ role: "system", content: createPrompt(authState.selectedOrg.name) }],
          temperature: 0.7,
        },
      })
        .unwrap()
        .then(async (res) => {
          const analysis = res.messages[res.messages.length - 1].content;
          if (!analysis) throw Error("Could not analyze document");
          const data = JSON.parse(analysis.replace(/```json\s*|\s*```/g, ""));
          setCreateProjectState(CreateProjectWithAIState.DocumentAnalyzed);
          if (data.documentPages) setPagesAnalyzed(data.documentPages);
          else throw Error("Failed to analyze document.");
          setProject((p) =>
            p
              ? {
                  ...p,
                  name: data.name,
                  description: data.description,
                  startDate: data.startDate,
                  endDate: data.endDate,
                  projectResponsible: getPersonByEmailOrName({
                    email: data.projectResponsible?.email,
                    fullName: data.projectResponsible?.fullName,
                  }),
                  sourcingResponsible: getPersonByEmailOrName({
                    email: data.sourcingResponsible?.email,
                    fullName: data.sourcingResponsible?.fullName,
                  }),
                }
              : undefined
          );
          await updateProject({
            updateProjectRequest: {
              id: project.id,
              name: data.name,
              description: data.description ? { value: data.description } : undefined,
              startDate: data.startDate ? { value: data.startDate } : undefined,
              endDate: data.endDate ? { value: data.endDate } : undefined,
              projectResponsible: {
                value: getPersonByEmailOrName({
                  email: data.projectResponsible?.email,
                  fullName: data.projectResponsible?.fullName,
                })?.id,
              },
              sourcingResponsible: data.sourcingResponsible
                ? {
                    value: getPersonByEmailOrName({
                      email: data.sourcingResponsible?.email,
                      fullName: data.sourcingResponsible?.fullName,
                    })?.id,
                  }
                : undefined,
            },
          })
            .unwrap()
            .catch((e) => {
              errorDisplayer.trigger(e);
            });
          setCreateProjectState(CreateProjectWithAIState.ProjectCreated);
        })
        .catch((e) => {
          errorDisplayer.trigger(e);
        });
    }
  }, [
    analyzeDocument,
    authState.selectedOrg.name,
    authState.selectedOrg.users,
    createProjectState,
    dispatch,
    errorDisplayer,
    getPersonByEmailOrName,
    project?.documents,
    project?.id,
    updateProject,
  ]);

  const { uppy } = useMemo(() => {
    const uppy = new Uppy({
      id: "AwsS3",
      autoProceed: true,
      onBeforeFileAdded(currentFile) {
        currentFile["id"] = uuid();
        return currentFile;
      },
    })
      .use(ProgressBar, { target })
      .use(AwsS3, {
        id: "AwsS3",
        limit: 1,
        async getUploadParameters(file) {
          if (!isFile(file.data)) throw Error("Only files, not blobs, are supported.");
          setCreateProjectState(CreateProjectWithAIState.IsInitializingProject);
          const initialProject = await createProject({ createProjectRequest: { name: "" } })
            .unwrap()
            .catch((e) => {
              errorDisplayer.trigger(e);
              throw e;
            });
          setCreateProjectState(CreateProjectWithAIState.ProjectInitialized);
          setProject(initialProject);
          const upload = await createUpload({
            projectId: initialProject.id,
            uploadId: file.id,
            createUploadRequestDto: {
              fileName: file.name,
              fileSize: file.size,
              fileExtension: file.extension,
              mimeType: file.data.type,
            },
          })
            .unwrap()
            .catch((e) => {
              errorDisplayer.trigger(e);
              throw e;
            });
          setCreateProjectState(CreateProjectWithAIState.UploadCreated);
          return {
            url: upload.url,
            method: "POST",
            fields: {
              "x-amz-date": upload.renamedXAmzDate,
              "x-amz-signature": upload.renamedXAmzSignature,
              "x-amz-algorithm": upload.renamedXAmzAlgorithm,
              "x-amz-credential": upload.renamedXAmzCredential,
              policy: upload.policy,
              key: upload.key,
            },
          };
        },
      });

    return { uppy };
  }, [createProject, createUpload, errorDisplayer, target]);

  return (
    <Flex flexDirection="column" height="50vh">
      <FormControl py="4" display={project || isCreatingProject ? "none" : "block"}>
        <FormLabel>{t("Add project document")}</FormLabel>
        <div id="dnd">
          <DragDrop uppy={uppy} />
        </div>
      </FormControl>
      {createProjectState === CreateProjectWithAIState.IsInitializingProject && (
        <Flex alignItems={"center"} pt="4">
          <Spinner size="xs" thickness="2px" color="smSecondary" mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Creating project")}...
          </Text>
        </Flex>
      )}
      {createProjectState >= CreateProjectWithAIState.ProjectInitialized && (
        <Flex alignItems={"center"} pt="4">
          <Icon as={FaCheckCircle} color={"green.400"} mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Project created")}
          </Text>
        </Flex>
      )}
      {createProjectState === CreateProjectWithAIState.UploadCreated && (
        <Flex alignItems={"center"}>
          <Spinner size="xs" thickness="2px" color="smSecondary" mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Scanning document")}...
          </Text>
        </Flex>
      )}
      {createProjectState >= CreateProjectWithAIState.UploadScanned && (
        <Flex alignItems={"center"}>
          <Icon as={FaCheckCircle} color={"green.400"} mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Document virus scan complete")}
          </Text>
        </Flex>
      )}
      {createProjectState === CreateProjectWithAIState.IsAnalyzingDocument && (
        <Flex alignItems={"center"}>
          {analyzeDocumentError ? (
            <>
              <Icon as={FaTimesCircle} color="red.400" mr="2" />
              <Text fontSize={"sm"} color="red.400">
                {t("Neural net analysis failed")}...
              </Text>
            </>
          ) : (
            <>
              <Spinner size="xs" thickness="2px" color="blue.200" mr="2" />
              <Text fontSize={"sm"} color="smMuted">
                {t("Running deep neural net analysis")}...
              </Text>
            </>
          )}
        </Flex>
      )}
      {createProjectState >= CreateProjectWithAIState.DocumentAnalyzed ? (
        <Flex alignItems="center" pb="4">
          {pagesAnalyzed ? (
            <Icon as={FaCheckCircle} color={"green.400"} mr="2" />
          ) : (
            <Icon as={FaTimesCircle} color="red.400" mr="2" />
          )}
          {pagesAnalyzed ? (
            <Text fontSize={"sm"} color="smMuted">
              {t("Deep neural net analysis complete")}. {t("Analyzed")} {pagesAnalyzed} {t("pages")}.
            </Text>
          ) : (
            <Text fontSize={"sm"} color="smMuted">
              {t("Neural net analysis failed")}. {t("Make sure the document is a valid PDF with selectable text")}.
            </Text>
          )}
        </Flex>
      ) : null}
      {createProjectState === CreateProjectWithAIState.ProjectCreated && project && (
        <Flex flexDirection={"column"} overflow="auto" backgroundColor={"smBackgroundSecondary"} p="4">
          {project.name && (
            <Text fontWeight={"bold"} pt="4">
              {project.name}
            </Text>
          )}
          {project.externalId && (
            <Text fontSize={"sm"} py="2">
              {project.externalId}
            </Text>
          )}
          {project.projectType && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Project type")}</Text>
              <Text fontWeight={"bold"}>{project.projectType.name}</Text>
            </Flex>
          )}
          {project.startDate && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Start date")}</Text>
              <Text fontWeight={"bold"}>{project.startDate}</Text>
            </Flex>
          )}
          {project.endDate && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("End date")}</Text>
              <Text fontWeight={"bold"}>{project.endDate}</Text>
            </Flex>
          )}
          {project.parent && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Parent project")}</Text>
              <Text fontWeight={"bold"}>
                {`${project.parent?.externalId ? `${project.parent.externalId} - ` : ""}${project.parent?.name ?? ""}`}
              </Text>
            </Flex>
          )}
          {project.projectResponsible && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Project responsible")}</Text>
              <Text fontWeight={"bold"}>{displayPersonNameWithEmail(project.projectResponsible)}</Text>
            </Flex>
          )}
          {project.sourcingResponsible && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Sourcing responsible")}</Text>
              <Text fontWeight={"bold"}>{displayPersonNameWithEmail(project.sourcingResponsible)}</Text>
            </Flex>
          )}
          {project.description && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Description")}</Text>
              <Text>{project.description}</Text>
            </Flex>
          )}
        </Flex>
      )}
      <Flex justifyContent={"end"} alignItems={"end"} pt="4" flexGrow={"1"}>
        <Button
          colorScheme="teal"
          isDisabled={createProjectState !== CreateProjectWithAIState.ProjectCreated}
          onClick={() => {
            if (!project) return;
            setShowModal(false);
            navigate(urls.projects.view.go(project.id));
          }}
        >
          {t("Next")}
        </Button>
      </Flex>
    </Flex>
  );
};
