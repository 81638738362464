import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ViewDocumentAsOwnerDto } from "../../autogen/bff-api";
import { displayDateWithTime } from "../formatting/displayDateWithTime";
import { Explanation } from "../support/Explanation/Explanation";
import { DownloadDocumentIconButton } from "./DownloadDocumentIconButton/DownloadDocumentIconButton";

interface Props {
  documents: ViewDocumentAsOwnerDto[];
}

export const DocumentsAsOwnerTable = ({ documents }: Props) => {
  const { t } = useTranslation();
  return (
    <Box borderRadius={"md"} border="1px solid" borderColor="smBorder" pb="2.5">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>{t("Name")}</Th>
            <Th>{t("Upload date")}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {documents.map((e) => {
            return (
              <Tr key={e.id}>
                <Td>{e.name}</Td>
                <Td>{displayDateWithTime(e.uploadedAt, t("at"))}</Td>
                <Td>
                  <Flex justifyContent="end">
                    <Explanation text={t("Download document")} shouldWrapChildren>
                      <DownloadDocumentIconButton documentId={e.id} />
                    </Explanation>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
          {documents.length === 0 && (
            <Tr>
              <Td colSpan={3} width={"100%"} textAlign="center" fontSize={"small"}>
                {t("No documents have been uploaded")}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
