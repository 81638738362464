import { Button, Icon, Td, Text, Tr, useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { ContractTaskDto, useDeleteContractTaskMutation } from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { displayDate } from "../../../../common/formatting/displayDate";
import { ContractTaskResponsibleParty } from "./ContractTaskResponsibleParty";
import { ContractTaskStatusTag } from "./ContractTaskStatusTag";

interface Props {
  onRemoved: () => Promise<void>;
  task: ContractTaskDto;
  showTask: (id: string) => void;
}

export const TaskRow = ({ onRemoved, task, showTask }: Props) => {
  const { t } = useTranslation();
  const [update] = useDeleteContractTaskMutation();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const displayer = useApiError();

  const deleteTask = useCallback(async () => {
    setIsLoading(true);
    const result = await update({ taskId: task.id });
    if ("data" in result) {
      await onRemoved();
      toast({ title: t("Task removed"), status: "info" });
    } else displayer.trigger(result.error);
    setIsLoading(false);
  }, [displayer, onRemoved, t, task.id, toast, update]);

  return (
    <Tr
      key={task.id}
      _hover={{ backgroundColor: "smBackgroundSecondary", cursor: "pointer" }}
      _last={{ borderBottom: "none" }}
      onClick={() => showTask(task.id)}
    >
      <Td>
        <Text fontSize={"sm"}>{task.title}</Text>
      </Td>
      <Td>
        <Text fontSize={"sm"}>{displayDate(task.dueDate)}</Text>
      </Td>
      <Td>
        <ContractTaskResponsibleParty task={task} />
      </Td>
      <Td>
        <ContractTaskStatusTag status={task.status} />
      </Td>
      <Td display={"flex"} justifyContent="end">
        {task.canDelete && (
          <Button
            leftIcon={<Icon as={FaTimes} w="15px" h="15px" />}
            colorScheme="red"
            variant="outline"
            size={"xs"}
            isLoading={isLoading}
            marginRight={1}
            onClick={(e) => {
              e.stopPropagation();
              deleteTask();
            }}
          >
            {t("Delete")}
          </Button>
        )}
      </Td>
    </Tr>
  );
};
