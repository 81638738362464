import { FormControl, FormHelperText, FormLabel, Grid, GridItem, Heading, useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateOrganizationMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { MultiSelector } from "../../../../common/input/Selector/MultiSelector";
import { Layout } from "../../../../common/layout/Layout";
import { CONTENT_WIDTH } from "../../../../config";
import { displayPersonNameWithEmail } from "../../../contracts/view-single/sharing/AddExternalParticipantModal";
import { ChangeAboutUs } from "./ChangeAboutUs";
import { ChangeCountry } from "./ChangeCountry";
import { ChangeHomePageUrl } from "./ChangeHomePageUrl";
import { ChangeName } from "./ChangeName";
import { ChangeOrgNumber } from "./ChangeOrgNumber";
import { ChangeSize } from "./ChangeSize";
import { ChangeSpecializations } from "./ChangeSpecializations";

export interface EditCompanyProfileFormValues {
  name: string;
  about: string;
  specialties: string;
}

export const EditOrganizationProfile = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const authState = useLoggedInWithOrgContextState();
  const { t } = useTranslation();
  const [editOrg, { isLoading }] = useUpdateOrganizationMutation();
  const [contactPersons, setContactPersons] = useState(authState.selectedOrg.contactPersons);
  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`
                "leftContent content rightContent"
                `}
        px="2"
      >
        <GridItem
          area={"content"}
          paddingLeft={isLargerThan768 ? "0px" : "10px"}
          paddingRight={isLargerThan768 ? "0px" : "10px"}
        >
          <Grid h="100%" gridTemplateColumns={"1fr"} gridTemplateRows={"70px 1fr"}>
            <GridItem display="flex" alignItems="center" borderBottom={"1px solid #e1e6ee"}>
              <Heading as="h2" size="lg">
                {t("Edit company profile")}
              </Heading>
            </GridItem>
            <GridItem paddingBottom={"70px"}>
              <ChangeName currentValue={authState.selectedOrg.name} />
              <ChangeCountry currentValue={authState.selectedOrg.country.id} />
              <ChangeOrgNumber currentValue={authState.selectedOrg.organizationNumber} />
              <ChangeHomePageUrl currentValue={authState.selectedOrg.homePageUrl ?? ""} />
              <ChangeAboutUs currentValue={authState.selectedOrg.aboutUs ?? ""} />
              <ChangeSpecializations currentValue={authState.selectedOrg.specializations ?? ""} />
              <FormControl pt="4">
                <FormLabel>{t("Contact persons")}</FormLabel>
                <MultiSelector
                  isLoading={isLoading}
                  value={
                    contactPersons?.map((p) => ({
                      label: displayPersonNameWithEmail(p),
                      value: p.id,
                    })) ?? []
                  }
                  options={authState.selectedOrg.users.map((u) => ({
                    label: displayPersonNameWithEmail(u.person),
                    value: u.person.id,
                  }))}
                  onChange={async (values) => {
                    const org = await editOrg({
                      orgId: authState.selectedOrg.id,
                      editOrganizationRequest: {
                        contactPersons: { persons: values.map((v) => v.value) },
                      },
                    }).unwrap();
                    setContactPersons(org.contactPersons);
                  }}
                  noOptionsAvailableMessage={t("No persons found")}
                />
                <FormHelperText>{`${t("Select contact persons for your organization")}. ${t(
                  "These will be visible to other organizations"
                )}.`}</FormHelperText>
              </FormControl>
              <ChangeSize currentValue={authState.selectedOrg.size ?? null} />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
