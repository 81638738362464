import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useUpdateOrganizationTenantMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../../../common/redux/reducers/authStateReducer";

interface Props {
  currentValue: string;
}

interface FormValues {
  tenantId: string;
}

export const ChangeAzureTenant = ({ currentValue }: Props) => {
  const toast = useToast();
  const authState = useLoggedInWithOrgContextState();
  const [editOrg, { isLoading }] = useUpdateOrganizationTenantMutation();
  const dispatch = useAppDispatch();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { t } = useTranslation();
  const displayer = useApiError();

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      tenantId: currentValue,
    },
  });

  const value = watch("tenantId");

  const onSubmit = async (values: FormValues) => {
    setIsRefreshing(true);
    if (values.tenantId !== undefined && values.tenantId.trim().length > 0) {
      const result = await editOrg({
        orgId: authState.selectedOrg.id,
        updateOrgTenantRequest: {
          tenantId: values.tenantId.trim(),
        },
      });
      if ("data" in result) {
        dispatch(updateSelectedOrg(result.data as OrgDto));
      } else {
        displayer.trigger(result.error);
      }
    } else {
      const result = await editOrg({
        orgId: authState.selectedOrg.id,
        updateOrgTenantRequest: {
          tenantId: undefined,
        },
      });
      if ("data" in result) {
        dispatch(updateSelectedOrg(result.data as OrgDto));
      } else {
        displayer.trigger(result.error);
      }
    }
    setIsRefreshing(false);
    toast({ title: t("Tenant ID has been updated!"), status: "success" });
  };
  return (
    <Box backgroundColor="smBackgroundSecondary" rounded="lg" p="5" mt="5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl mt="15px" isInvalid={errors.tenantId !== undefined}>
          <FormLabel htmlFor={"tenantId"}>{t("Azure tenant ID")}</FormLabel>
          <Input
            id={"tenantId"}
            type="text"
            backgroundColor="smBackground"
            {...register("tenantId", {
              minLength: {
                value: 2,
                message: t("Must be at least 2 characters long"),
              },
            })}
          />
          <FormErrorMessage>{errors.tenantId && errors.tenantId.message}</FormErrorMessage>
          <FormHelperText>{t("Provide your Tenant ID to enable Entra ID Single Sign-On")}.</FormHelperText>
        </FormControl>
        {currentValue.trim() !== value.trim() && (
          <Button
            mt="10px"
            leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
            variant={"solid"}
            colorScheme="teal"
            size={"sm"}
            isLoading={isLoading || isRefreshing}
            type="submit"
          >
            {t("Update")}
          </Button>
        )}
      </form>
    </Box>
  );
};
