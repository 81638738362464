import { useCallback, useContext } from "react";
import { ddRum } from "../../../main";
import { RumComponentContext } from "./RumComponentContext";

export const useRumError = () => {
  const componentContext = useContext(RumComponentContext);

  const errorHandler = useCallback(
    (error: unknown, customAttributes?: object) => {
      // Inside the callback, handle uninitialized RUM.
      if (!ddRum || !ddRum.addError) {
        console.warn("Datadog RUM SDK is not initialized.");
        return;
      }

      ddRum.addError(error, {
        ...componentContext.customAttributes,
        ...customAttributes,
        react: {
          breadcrumbs: componentContext.componentBreadCrumbs,
          component: componentContext.component,
          ...(customAttributes as { react: Record<string, unknown> } | undefined)?.react,
        },
      });
    },
    [componentContext.component, componentContext.componentBreadCrumbs, componentContext.customAttributes]
  );

  return errorHandler;
};
