import { CopyIcon } from "@chakra-ui/icons";
import {
  Button,
  Code,
  Flex,
  FormControl,
  FormHelperText,
  Icon,
  IconButton,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { t } from "i18next";
import { FaCheck, FaKey } from "react-icons/fa";
import { useCreateApiClientMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";

export const ApiAccess = () => {
  const authState = useLoggedInWithOrgContextState();
  const [createClient, { isLoading, data }] = useCreateApiClientMutation();

  const { hasCopied: hasCopiedClientId, onCopy: onCopyClientId } = useClipboard(data?.clientId ?? "");
  const { hasCopied: hasCopiedSecret, onCopy: onCopySecret } = useClipboard(data?.secret ?? "");
  const { hasCopied: hasCopiedPersonId, onCopy: onCopyPersonId } = useClipboard(authState.me.id);
  const { hasCopied: hasCopiedOrganizationId, onCopy: onCopyOrganizationId } = useClipboard(authState.selectedOrg.id);

  return (
    <Flex flexDirection={"column"}>
      <Text fontWeight={"semibold"} py="2">
        {t("Person ID")}
      </Text>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="smBackgroundSecondary"
        rounded="md"
        p="2"
      >
        <Code backgroundColor="transparent" display="flex" alignItems="center">
          {authState.me.id}
        </Code>
        {!hasCopiedPersonId && (
          <IconButton aria-label="Copy person id" icon={<CopyIcon />} onClick={onCopyPersonId} size="sm" isRound />
        )}
        {hasCopiedPersonId && (
          <Flex width="8" height="8" justifyContent="center" alignItems="center">
            <Icon as={FaCheck} />
          </Flex>
        )}
      </Flex>
      <Text fontWeight={"semibold"} py="2">
        {t("Organization ID")}
      </Text>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="smBackgroundSecondary"
        rounded="md"
        p="2"
      >
        <Code backgroundColor="transparent" display="flex" alignItems="center">
          {authState.selectedOrg.id}
        </Code>
        {!hasCopiedOrganizationId && (
          <IconButton
            aria-label="Copy organization id"
            icon={<CopyIcon />}
            onClick={onCopyOrganizationId}
            size="sm"
            isRound
          />
        )}
        {hasCopiedOrganizationId && (
          <Flex width="8" height="8" justifyContent="center" alignItems="center">
            <Icon as={FaCheck} />
          </Flex>
        )}
      </Flex>
      {data ? (
        <>
          <Text fontWeight={"semibold"} py="2">
            {t("Client ID")}
          </Text>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            backgroundColor="smBackgroundSecondary"
            rounded="md"
            p="2"
          >
            <Code backgroundColor="transparent" display="flex" alignItems="center">
              {data.clientId}
            </Code>
            {!hasCopiedClientId && (
              <IconButton aria-label="Copy client id" icon={<CopyIcon />} onClick={onCopyClientId} size="sm" isRound />
            )}
            {hasCopiedClientId && (
              <Flex width="8" height="8" justifyContent="center" alignItems="center">
                <Icon as={FaCheck} />
              </Flex>
            )}
          </Flex>
          <Text fontWeight={"semibold"} py="2">
            {t("Secret")}
          </Text>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            backgroundColor="smBackgroundSecondary"
            rounded="md"
            p="2"
          >
            <Code backgroundColor="transparent" display="flex" alignItems="center">
              {data.secret}
            </Code>
            {!hasCopiedSecret && (
              <IconButton aria-label="Copy secret" icon={<CopyIcon />} onClick={onCopySecret} size="sm" isRound />
            )}
            {hasCopiedSecret && (
              <Flex width="8" height="8" justifyContent="center" alignItems="center">
                <Icon as={FaCheck} />
              </Flex>
            )}
          </Flex>
        </>
      ) : (
        <FormControl flexDirection="column" pt="5">
          <Button leftIcon={<Icon as={FaKey} />} isLoading={isLoading} onClick={() => createClient({})}>
            {t("Generate API Client")}
          </Button>
          <FormHelperText>
            {t("Store the credentials safely")}. {t("They can only be viewed once")}.
          </FormHelperText>
        </FormControl>
      )}
    </Flex>
  );
};
