import { ChakraProvider, Link, Text } from "@chakra-ui/react";
import { datadogRum } from "@datadog/browser-rum";
import i18n from "i18next";
import "moment/dist/locale/nb";
import React, { ReactNode, useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter, useLocation } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import en from "../locales/en/translation.json";
import no from "../locales/no/translation.json";
import { App } from "./app";
import { ErrorBoundary } from "./common/errors/ErrorBoundary/ErrorBoundary";
import { getPreferredLanguage } from "./common/local-storage/language";
import { store } from "./common/redux/store";
import { theme as chakraTheme, requireStringEnvVar } from "./config";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    no: {
      translation: no,
    },
  },
  lng: getPreferredLanguage().toLowerCase(),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

datadogRum.init({
  applicationId: requireStringEnvVar("VITE_DATADOG_APPLICATION_ID"),
  clientToken: requireStringEnvVar("VITE_DATADOG_CLIENT_TOKEN"),
  site: "datadoghq.eu",
  service: "sm-webapp",
  env: requireStringEnvVar("VITE_DATADOG_ENV"),
  version: "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

export const ddRum = datadogRum;

const ScrollToTopWrapper = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return <>{children}</>;
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ErrorBoundary
    fallback={
      <div>
        Sorry, that was not supposed to happen. Our team has been notified.{" "}
        <Text>
          {" "}
          You can{" "}
          <Link color="smPrimary" href="/">
            Click here
          </Link>{" "}
          to the back to the home page.
        </Text>
      </div>
    }
    scope="application"
  >
    <Provider store={store}>
      <React.StrictMode>
        <ChakraProvider theme={chakraTheme}>
          <BrowserRouter>
            <ScrollToTopWrapper>
              <IntercomProvider
                appId={requireStringEnvVar("VITE_INTERCOM_APP_ID")}
                apiBase={requireStringEnvVar("VITE_INTERCOM_BASE_URL")}
                autoBoot={true}
              >
                <App />
              </IntercomProvider>
            </ScrollToTopWrapper>
          </BrowserRouter>
        </ChakraProvider>
      </React.StrictMode>
    </Provider>
  </ErrorBoundary>
);
